<template>
    <RequestsTable
        with-hide-finished-filter
        grid-view
        creatable
    />
</template>

<script>
import RequestsTable from '@/components/entityTables/RequestsTable.vue';

export default {
  components: { RequestsTable },
  setup() {},
};
</script>
